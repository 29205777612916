import React, { useState, useEffect, useContext } from "react";
import VerifyOwnership from "./VerifyOwnership";
import SetNewPin from "./SetNewPin";
import DashboardContext from "context/Dashboard/DashboardContext";
import VerifyOwnerPhone from "./VerifyOwnerPhone";

const ChangeTransactionPin = () => {
  const [step, setStep] = useState<number>(1);
  const handleStep = () => {
    setStep(step + 1);
  };

  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);

  useEffect(() => {
    setCurrentPagename("Confirm OTP");
    if (step === 2) {
      setCurrentPagename("Verify Phone Number");
    }
    if (step === 3) {
      setCurrentPagename("Set New PIN");
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("var(--gray-light)") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("var(--gray-light)") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
    step,
  ]);
  return (
    <div>
      {step === 1 && <VerifyOwnership onClick={handleStep} />}
      {step === 2 && <VerifyOwnerPhone onClick={handleStep} />}
      {step === 3 && <SetNewPin onClick={handleStep} />}
    </div>
  );
};

export default ChangeTransactionPin;
