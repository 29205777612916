import { api } from "app/ApiSlice";

// interface IsignUp {
//     first_name: string
//     last_name: string
//     email: string
//     mobile_number: string
//     login_pin: string
//     device_id: string
//     referral_id: string
// }

const authenticationSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (body) => ({
        url: `/v1/account/create-account`,
        body,
        method: "POST",
      }),
    }),

    verifyOtp: builder.mutation({
      query: (body) => ({
        url: `/v1/account/verify-otp`,
        body,
        method: "POST",
      }),
    }),

    verifyPhoneOtp: builder.mutation({
      query: (body) => ({
        url: `/v1/account/verify-sms-otp`,
        body,
        method: "POST",
      }),
    }),

    resendPhoneOtp: builder.mutation({
      query: (body) => ({
        url: `/v1/account/resend-sms-otp`,
        body,
        method: "POST",
      }),
    }),

    resendOtp: builder.mutation({
      query: (body) => ({
        url: `/v1/account/send-otp`,
        body,
        method: "POST",
      }),
    }),
    getSecurityQuestion: builder.query({
      query: () => "/v1/account/get-security-questions",
    }),

    answerSecurityQuestion: builder.mutation({
      query: (body) => ({
        url: `/v1/account/answer-security-question`,
        body,
        method: "POST",
      }),
    }),
    createTransactionPin: builder.mutation({
      query: (body) => ({
        url: `/v1/account/create-transaction-pin`,
        body,
        method: "POST",
      }),
    }),
    resetTransactionPin: builder.mutation({
      query: (body) => ({
        url: `/v1/account/reset-transaction-pin`,
        body,
        method: "POST",
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),

    // LOGIN PAGE MUTATION
    signIn: builder.mutation({
      query: (body) => ({
        url: "/v1/auth/login",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Auth"],
    }),

    // RESET LOGIN PIN
    resetLoginPin: builder.mutation({
      query: (body) => ({
        url: "/v1/account/reset-login-pin",
        body,
        method: "POST",
      }),
    }),

    //CREATE LOGIN PIN
    createLoginPin: builder.mutation({
      query: (body) => ({
        url: "/v1/account/create-login-pin",
        body,
        method: "POST",
      }),
    }),

    // refreshLogin : builder.query({
    //     query : body => ({
    //         url: '/v1/auth/refresh'
    //     })
    // })
  }),
});

export const {
  useSignUpMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useGetSecurityQuestionQuery,
  useAnswerSecurityQuestionMutation,
  useCreateTransactionPinMutation,
  useSignInMutation,
  useResetLoginPinMutation,
  useCreateLoginPinMutation,
  useResetTransactionPinMutation,
  useVerifyPhoneOtpMutation,
  useResendPhoneOtpMutation,
} = authenticationSlice;
