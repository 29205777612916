import React, { useEffect, useState } from "react";
import ContainerWrapper from "../container/Container";
import { useNavigate } from "react-router-dom";
import Spinner from "components/Spinner/Spinner";
import { Toaster, toast } from "react-hot-toast";
import {
  useVerifyOtpMutation,
  useResendOtpMutation,
  useResetLoginPinMutation,
} from "features/Auth/AuthApiSlice";
import { useForm } from "react-hook-form";
import Pin from "components/form/Auth/Pin/Pin";
import Alert from "container/register/Alert";

const ResetPin = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string[]>([]);
  const [sendOtpToUser, { isLoading }] = useResetLoginPinMutation();
  const [verifyOtp, { isLoading: isLoadingVerifyOtp }] = useVerifyOtpMutation();
  const [email, setEmail] = useState("");
  const userEmail = sessionStorage.getItem("user_mail");
  const [id, setId] = useState("");
  const [sec, setSec] = useState<number>(100);
  useEffect(() => {
    const sessionId = sessionStorage.getItem("id");
    const mail = sessionStorage.getItem("mail");
    if (sessionId) {
      setId(sessionId);
    }
    if (mail) {
      setEmail(mail);
    }
  }, []);

  useEffect(() => {
    const timeout = setInterval(() => {
      setSec((param) => (sec > 0 ? param - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(timeout);
    };
  }, [sec]);

  //SEND OTP ON APP MOUNT
  const sendOtpToUserHandler = async () => {
    const formData = new FormData();
    formData.append("email", userEmail as string);
    try {
      const res = await sendOtpToUser(formData).unwrap();
      setId(res.data);
      setEmail(userEmail as string);
    } catch (error: any) {
      if (error.status === 401) {
        toast.error("We could not find your account");
      }
    }
  };

  useEffect(() => {
    if (userEmail) {
      sendOtpToUserHandler();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  const defaultValues = {
    id: id as string,
    otp: otp.join(""),
  };
  const { handleSubmit } = useForm({
    defaultValues,
  });

  // SUBMIT OTP
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("otp", otp.join(""));
    formData.append("id", id);

    try {
      const res = await verifyOtp(formData).unwrap();
      if (res.status) {
        localStorage.setItem("create_id", id);
        localStorage.setItem("otp", otp.join(""));
        navigate("/reset-phone-pin");
      }
    } catch (error: any) {
      if (error.status === 403) {
        if (error.data?.expired) {
          toast(
            (t) => (
              <Alert
                toast={toast}
                t={t}
                message={
                  <>
                    OTP has expired! Click on{" "}
                    <span className="font-bold px-1"> Resend Code</span> to get
                    another OTP
                  </>
                }
              />
            ),
            {
              duration: 10000,
            }
          );
        } else {
          toast.error("Invalid OTP");
        }
      } else {
        toast.error("An error occured, Please try again");
      }
    }
  };

  //RESEND A NEW OTP
  const [resendOtp, { isLoading: isLoadingResend }] = useResendOtpMutation();
  const resendOtpHandler = async () => {
    try {
      const res = await resendOtp({ id: id as string }).unwrap();
      if (res) {
        setId(res.data);
        toast.success(res.message);
      }
    } catch (error: any) {
      if (error) {
        toast.error("An error occured, Please try again");
      }
    }
  };

  return (
    <ContainerWrapper>
      <Toaster
        toastOptions={{
          duration: 6000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading || isLoadingVerifyOtp || isLoadingResend ? <Spinner /> : null}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="2xl:pt-[3.9375rem]  p-[2.1875rem] grid items-center place-content-center"
      >
        <img
          onClick={() => navigate(-1)}
          width={15}
          height={19}
          src="/Auth/Arrow.svg"
          alt="back"
          className="w-[0.9375rem] h-[1.1875rem] xl:mb-[3.0625rem] mb-[1.25rem]"
        />
        <div className="2xl:mb-[8.1875rem] md:mb-[4.0625rem]">
          <h1 className="text-dark text-[1.875rem] font-semibold mb-[0.5rem]">
            Reset Login PIN
          </h1>
          {email ? (
            <h6 className="font-[400] text-[var(--dark-gray)] text-[1rem] ">
              We have sent a code to your email{" "}
              <span className="font-nunito font-[700]">{email} </span>
            </h6>
          ) : null}
        </div>
        {/* //!! TODO: O */}
        <div className="flex flex-col gap-[4rem] 2xl:mb-[5.125rem] lg:mb-[3.375rem] mb-[2.1875rem] ">
          <Pin myPin={setOtp} title="" tokenNumber={6} />
          <div className="flex gap-[0.25rem] ">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="text-[var(--text-1)] font-[400] text-[0.75rem]">
              Didn't get the code?
            </p>
            <button
              onClick={resendOtpHandler}
              disabled={isLoadingResend || sec > 0}
              className="text-[var(--black)] font-[400] text-[0.75rem] relative disabled:cursor-not-allowed"
            >
              <span className=" hover:font-semibold">
                Resend Code {sec > 0 ? `in ${sec} sec` : ""}{" "}
              </span>{" "}
              <span className="absolute top-[1.25rem] right-[0.5rem]">
                <img
                  src="/Auth/rule.png"
                  alt="rule"
                  width={96}
                  height={10}
                  className="w-[0.625rem] h-[0.0625rem]"
                />
              </span>
            </button>
          </div>
        </div>
        <button className="btn-primary transition-all">
          {/* <Link to="/create-pin"> */}
          Continue
          {/* </Link> */}
        </button>
      </form>
    </ContainerWrapper>
  );
};

export default ResetPin;
