import VerifyPhoneNumber from "container/register/VerifyPhoneNumber";
import React from "react";




const VerifyPhoneNumberPage = () => {
    return (
        <VerifyPhoneNumber />
    )

}

export default VerifyPhoneNumberPage;