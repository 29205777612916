import { Grid } from "@chakra-ui/react";

import ContainerWrapper from "../container/Container";
import style from "./general.module.scss";

import { useState, useEffect } from "react";

import Spinner from "components/Spinner/Spinner";
import { Toaster, toast } from "react-hot-toast";
import Pin from "components/form/Auth/Pin/Pin";

import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "components/successpage/Modal";
import LoginSuccessful from "components/successpage/LoginSuccessful";
import { useForm } from "react-hook-form";
import { useCreateTransactionPinMutation } from "features/Auth/AuthApiSlice";

interface IDefaultValues {
  id: string;
  pin: string;
  confirm_pin: string;
  device_id: string;
  otp: string;
  phone_otp: string;
}
const PinRegistration = () => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string[]>([]);
  const [ConfirmOtp, setConfirmOtp] = useState<string[]>([]);
  const [matchOtp, setMatchOtp] = useState<boolean>(false);

  //create Transaction Pin
  const [createTransactionPin, { isLoading, isSuccess }] =
    useCreateTransactionPinMutation();

  //use form
  const { handleSubmit } = useForm({
    defaultValues: {
      id: "",
      pin: "",
      confirm_pin: "",
      device_id: "web",
      otp: "",
      phone_otp: "",
    },
  });

  useEffect(() => {
    if (otp.join("") === ConfirmOtp.join("")) {
      setMatchOtp(true);
    } else {
      setMatchOtp(false);
    }
  }, [otp, ConfirmOtp]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isSuccess) {
        navigate("/");
      }
    }, 7000);
    return () => clearTimeout(timer);
  }, [isSuccess, navigate]);

  //submit handler
  const onSubmit = async (data: IDefaultValues) => {
    const formData = new FormData();
    const id = localStorage.getItem("id");
    const otp_pin = localStorage.getItem("otp");
    const phone_otp = localStorage.getItem("phone_otp");

    formData.append("id", id as string);
    formData.append("pin", otp.join(""));
    formData.append("confirm_pin", ConfirmOtp.join(""));
    formData.append("device_id", data.device_id);
    formData.append("otp", otp_pin as string);
    formData.append("phone_otp", phone_otp as string);

    try {
      const res = await createTransactionPin(formData).unwrap();

      localStorage.setItem("user", JSON.stringify(res?.data));
    } catch (error: any) {
      toast.error("Something went wrong , please try again later");
    }
  };

  return (
    <ContainerWrapper>
      <Toaster
        toastOptions={{
          duration: 7000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading ? <Spinner /> : null}
      <Grid
        as={"form"}
        placeContent={"center"}
        onSubmit={handleSubmit(onSubmit)}
        className={` ${style.small__mobile} `}
      >
        <Grid
          gap={{
            base: "2rem",
            lg: "3rem",
            "2xl": "2rem",
          }}
        >
          <a href="https://jarapay.co/">
            <img
              src="/jarapay.svg"
              alt="jarapay"
              width={130}
              height={35}
              className="lg:w-[8.125rem] h-[2.1875rem] "
            />
          </a>
          <img
            onClick={() => navigate(-1)}
            width={15}
            height={19}
            src="/Auth/Arrow.svg"
            alt="back"
            className="w-[1.5rem] h-[1.9rem] mb-[3.063rem]"
          />

          <div className=" ">
            <h1 className="text-[var( --gray-scale-text)] md:text-[1.875rem] text-[1.25rem]  font-[700] mb-[0.5rem] text-center md:text-left font-nunito">
              Create Transaction PIN
            </h1>
            <h6 className="font-[400] text-[var( --gray-scale-text)] text-[1rem] text-center md:text-left  ">
              Create your Pin to make transactions more secure
            </h6>
          </div>

          <Grid
            gap={{
              base: ".6rem",
              lg: ".7rem",
              "2xl": "1rem",
            }}
            className=" relative "
            padding={{
              base: ".6rem",
              lg: "0",
            }}
          >
            <Pin myPin={setOtp} title="Enter PIN" tokenNumber={4} />
            <Pin myPin={setConfirmOtp} title="Confirm PIN" tokenNumber={4} />
            <p className="text-red text-[0.75rem] absolute bottom-[-1.25rem]">
              {ConfirmOtp.join("")?.length > 0 && matchOtp === false
                ? "PIN do not match"
                : ""}
            </p>
          </Grid>
          <div className=" grid place-items-center xl:block">
            <button
              disabled={
                ConfirmOtp.join("")?.length < 4 ||
                otp.join("")?.length < 4 ||
                matchOtp === false ||
                isLoading
              }
              className="btn-primary  transition-all disabled:cursor-not-allowed mt-4"
            >
              Create Pin
            </button>
          </div>
        </Grid>

        {isSuccess ? (
          <Modal handleClose={() => setOpen(true)} open={open}>
            <LoginSuccessful
              title="Congratulations"
              message={
                <>
                  Welcome to JaraPay, do not share your transaction PIN with
                  anyone.
                </>
              }
              buttonText="Explore Now"
            />
          </Modal>
        ) : null}
      </Grid>
    </ContainerWrapper>
  );
};

export default PinRegistration;
