import React, { useEffect, useState } from "react";
import style from "./transactionpin.module.scss";
import Pin from "components/form/Auth/Pin/Pin";
import { useAppSelector } from "hooks/redux";
import {
  useVerifyPhoneOtpMutation,
  useResendPhoneOtpMutation,
} from "features/Auth/AuthApiSlice";
import { toast } from "react-hot-toast";

import Loader from "components/Spinner/Spinner";
import { Box, Button } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

const VerifyOwnerPhone = ({ onClick }: { onClick: () => void }) => {
  const [pin, setPin] = useState<any>([]);
  const { user } = useAppSelector((state) => state.auth) as any;
  const my_id = sessionStorage.getItem("my_id");

  const [verifyOTP, { isLoading: isLoadingVerification }] =
    useVerifyPhoneOtpMutation();

  const [resendOTP] = useResendPhoneOtpMutation();
  const [count, setCount] = useState(30);
  const [mounted, setMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState<any>();
  const { handleSubmit } = useForm();

  const sendOTPHandler = async () => {
    if (count !== 0) {
      return;
    }

    setIsLoading(true);

    try {
      const res = await resendOTP({ id: my_id as string }).unwrap();
      if (res) {
        setIsLoading(false);
        setCount(60);
      }

      if (count === 0) {
        toast.success("OTP sent successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setMounted(true);
  }, []);

  const handleClick = async () => {
    if (count === 0) {
      await sendOTPHandler();
    }
  };

  useEffect(() => {
    //reduce by 1
    const interval = setInterval(() => {
      setCount(count > 0 ? count - 1 : 0);
    }, 1000);
    //clear interval

    return () => clearInterval(interval);
  }, [count]);

  const onSubmitHandler = async () => {
    try {
      const res: any = await verifyOTP({
        id: my_id,
        otp: pin.join(",")?.replaceAll(",", ""),
      }).unwrap();

      toast.success(res.message);
      sessionStorage.setItem("phone_otp", pin.join(",")?.replaceAll(",", ""));
      onClick();
    } catch (error: any) {
      toast.error(error?.data?.error);
    }
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmitHandler)}
      className={`${style.small__mobile} md:pt-[3.9375rem] md:pl-[8.4375rem] lg:pl-[4.0625rem] 2xl:pl-[8.4375rem] p-[2.1875rem] grid place-content-center place-items-center`}
    >
      <div className=" mb-[4.6875rem] grid place-items-center">
        <h1 className="text-dark text-[1.875rem] font-bold mb-[0.5rem]">
          Verify Phone Number
        </h1>
        <h6 className="font-[400] text-[var(--dark-gray)] text-[1rem] text-center ">
          We have sent a code to your phone{" "}
          <span className="font-nunito font-[700]">
            {user?.data?.mobile_number}
          </span>
        </h6>
      </div>

      <div className="flex flex-col gap-[2rem] 2xl:mb-[8rem]  lg:mb-[5.375rem] mb-[4.0625rem] ">
        <Pin myPin={setPin} title=" " tokenNumber={6} />
        <div className="flex  gap-[0.25rem] justify-center">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p className="text-[var(--text-1)] font-[400] text-[0.625rem] md:text-[0.875rem]">
            Didn't get the code?{" "}
          </p>
          <p className="text-[var(--black)] font-[400] relative text-[0.625rem] md:text-[0.875rem]">
            <Box as="span" onClick={handleClick} className="cursor-pointer">
              {isLoading ? "Please wait..." : ` Resend Code in ${count} Secs`}
            </Box>{" "}
            <Box as="span" className="absolute top-[1.25rem] right-[0.5rem]">
              <img
                src="/Auth/rule.png"
                alt="rule"
                width={96}
                height={10}
                className="w-[2.6875rem] h-[0.3125rem]"
              />
            </Box>
          </p>
        </div>
      </div>

      <button
        disabled={pin.length !== 6 ? true : false}
        type="submit"
        className="btn-primary transition-all disabled:cursor-not-allowed"
      >
        Continue
      </button>
      {isLoadingVerification ? <Loader /> : null}
    </Box>
  );
};

export default VerifyOwnerPhone;
