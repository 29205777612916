import React, { useState, useEffect } from "react";
import ContainerWrapper from "../container/Container";
import { Link } from "react-router-dom";
import style from "./general.module.scss";
import { useForm } from "react-hook-form";

import Spinner from "components/Spinner/Spinner";
import { Toaster, toast } from "react-hot-toast";
import {
  useVerifyPhoneOtpMutation,
  useResendPhoneOtpMutation,
} from "features/Auth/AuthApiSlice";
import { useNavigate } from "react-router-dom";
import Pin from "components/form/Auth/Pin/Pin";
import Alert from "./Alert";
import { Grid } from "@chakra-ui/react";

interface IVerifyOtp {
  id: string | number;
  otp: string;
}

const VerifyPhoneNumber = () => {
  // const phone_number = localStorage.getItem("phone");
  const id = localStorage.getItem("id");
  const [otp, setOtp] = useState<string[]>([]);
  const [verifyOtp, { isLoading }] = useVerifyPhoneOtpMutation();
  const navigate = useNavigate();
  const [sec, setSec] = useState<number>(5);

  useEffect(() => {
    const timeout = setInterval(() => {
      setSec((param) => (sec > 0 ? param - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(timeout);
    };
  }, [sec]);

  const defaultValues: IVerifyOtp = {
    id: id as string,
    otp: otp.join(""),
  };
  const { handleSubmit } = useForm({
    defaultValues,
  });

  // SUBMIT OTP
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("otp", otp.join(""));
    formData.append("id", data.id);

    try {
      const res = await verifyOtp(formData).unwrap();
      if (res.status) {
        localStorage.setItem("data", JSON.stringify(res.data));
        localStorage.setItem("phone_otp", otp.join(""));
        navigate("/sign-up/security-registration");
      }
    } catch (error: any) {
      if (error.status === 403) {
        if (error.data?.expired) {
          toast(
            (t) => (
              <Alert
                toast={toast}
                t={t}
                message={
                  <>
                    OTP has expired! Click on{" "}
                    <span className="font-bold px-1"> Resend Code</span> to get
                    another OTP
                  </>
                }
              />
            ),
            {
              duration: 10000,
            }
          );
        } else {
          toast.error("Invalid OTP");
        }
      } else {
        toast.error("An error occured, Please try again");
      }
    }
  };

  //RESEND A NEW OTP
  const [resendOtp, { isLoading: isLoadingResend }] =
    useResendPhoneOtpMutation();
  const resendOtpHandler = async () => {
    try {
      const res = await resendOtp({ id: id as string }).unwrap();
      if (res) {
        toast.success(res.message);
      }
    } catch (error: any) {
      if (error) {
        toast.error("An error occured, Please try again");
      }
    }
  };

  return (
    <ContainerWrapper>
      <Toaster
        toastOptions={{
          duration: 7000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading || isLoadingResend ? <Spinner /> : null}
      <Grid
        as={"form"}
        placeContent={"center"}
        onSubmit={handleSubmit(onSubmit)}
        className={`${style.small__mobile} p-4 `}
      >
        <Grid
          gap={{
            base: "2.5rem",
            lg: "4rem",
            "2xl": "6rem",
          }}
        >
          <Grid
            gap={{
              base: "2rem",
              lg: "3rem",
              "2xl": "2rem",
            }}
          >
            <a href="https://jarapay.co/">
              <img
                src="/jarapay.svg"
                alt="jarapay"
                width={130}
                height={35}
                className="lg:w-[8.125rem] h-[2.1875rem] "
              />
            </a>

            <Link to="/sign-up">
              <img
                width={15}
                height={19}
                src="/Auth/Arrow.svg"
                alt="back"
                className="w-[0.938rem] h-[2.188rem] "
              />
            </Link>
            <div className="2xl:mb-[1rem] mb-[.5rem] ">
              <h1 className="text-dark text-[1.875rem] font-semibold mb-[0.5rem]">
                Complete Sign Up
              </h1>
              <h6 className="font-[400] text-[var(--dark-gray)] text-[1rem] ">
                We have sent a code to your phone number{" "}

              </h6>
            </div>
          </Grid>
          {/* //!! TODO: O */}
          <Grid
            gap={{
              base: "1rem",
              lg: ".1.5rem",
              "2xl": "2rem",
            }}
            className=" relative "
            padding={{
              base: ".6rem",
              lg: "0",
            }}
          >
            <Pin myPin={setOtp} title=" " tokenNumber={6} />
            <div className="flex  gap-[0.4rem] ">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <p className="text-[var(--text-1)] font-[400] text-[0.625rem] md:text-[0.875rem]">
                Didn't get the code?{" "}
              </p>
              <button
                type="button"
                disabled={isLoadingResend || sec > 0}
                onClick={resendOtpHandler}
                className={
                  " disabled:cursor-not-allowed text-[var(--black)] font-[400] relative text-[0.625rem] md:text-[0.875rem]"
                }
              >
                <span className=" hover:font-semibold">
                  Resend Code {sec > 0 ? `in ${sec} sec` : ""}{" "}
                </span>{" "}
                <span className="absolute top-[1.25rem] right-[0.5rem]">
                  <img
                    src="/Auth/rule.png"
                    alt="rule"
                    width={96}
                    height={10}
                    className="w-[2.688rem] h-[0.313rem]"
                  />
                </span>
              </button>
            </div>
          </Grid>
          {/* <Link to="/sign-up/security-registration" className="grid place-items-center md:block"> */}
          <button
            disabled={isLoading || otp.join("")?.length < 6}
            className=" disabled:opacity-75 disabled:cursor-not-allowed  btn-primary  transition-all"
          >
            Continue
          </button>
          {/* </Link> */}
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default VerifyPhoneNumber;
